import React from 'react';
import { CircularProgress } from '@material-ui/core';
import THEME from 'constants/theme';

export default function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <CircularProgress style={{ color: THEME.accent }} size={50} />
    </div>
  );
}
