import React, { useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { List } from '@material-ui/core';
import Paper from '../paper';
import TextInput from '../text_input';
import TopItem from '../top_libraries/top_item';
import SEARCH_QUERY from './query';
import Loading from '../Loading';
import styles from './styles';
import Title from '../title';
import Accordion from '../accordion';
import Checkbox from '../checkbox';
import RadioGroup from '../radio_group';
import PrimaryButton from '../primary_button';

export default function SearchSection() {
  const [
    state,
    setState,
  ] = useState({
    results : [],
    loading : false,
    text    : '',
    popular : false,
    date    : 'none',
  });

  const [
    loadSearch,
    { data, loading },
  ] = useLazyQuery(
    SEARCH_QUERY({ query: state.text, stars: state.popular ? '2000' : '300', date: _createDate(state.date) })
  );

  function _handleChangeText(text) {
    setState({
      ...state,
      text,
    });
  }
  function _handleChange(data) {
    setState({
      ...state,
      ...data,
    });
  }

  const classes = styles();
  return (
    <Paper>
      <Title>Find a JS library</Title>
      <TextInput text={state.text} onChange={_handleChangeText} />
      <Accordion title='Advance Search'>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <Checkbox title='Popular Repos' onChange={_handleChange} value={state.popular} />
          <RadioGroup onChange={_handleChange} />
        </div>
      </Accordion>
      <PrimaryButton color='primary' disabled={loading} onClick={state.text !== '' ? loadSearch : null} fullWidth>
        Search
      </PrimaryButton>
      <List className={classes.root}>
        {loading ? (
          <Loading />
        ) : data ? (
          data.search.edges.map((item, index) => <TopItem key={`LTI${index}`} {...item.node} />)
        ) : null}
      </List>
      <div id='clear' style={{ clear: 'both' }} />
    </Paper>
  );
}

function _createDate(date) {
  switch (date) {
    case 'month': {
      const date = Date.now();
      const today = new Date(date);
      const month = today.getMonth();
      const day = today.getDate();
      const year = today.getFullYear();
      return `${year}-${month}-${day}`;
    }
    case 'year': {
      const date = Date.now();
      const today = new Date(date);
      const month = today.getMonth();
      const day = today.getDate();
      const year = today.getFullYear() - 1;
      return `${year}-${month}-${day}`;
    }
    default: {
      return null;
    }
  }
}
