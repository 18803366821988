import { gql } from '@apollo/client';

const TOP_REPOSITORIES_QUERY = gql`
  query {
    search(query: "language:JavaScript stars:>20000", type: REPOSITORY, first: 15) {
      repositoryCount
      edges {
        node {
          ... on Repository {
            name
            description
            homepageUrl
            url
            openGraphImageUrl
            stargazers {
              totalCount
            }
            forks {
              totalCount
            }
            updatedAt
          }
        }
      }
    }
  }
`;

export default TOP_REPOSITORIES_QUERY;
