import { makeStyles } from '@material-ui/core/styles';
import THEME from 'constants/theme';

const useStyles = makeStyles((theme) => ({
  root      : {
    width           : '100%',
    backgroundColor : theme.palette.background.paper,
    top             : 0,
    marginTop       : 30,
  },
  heading   : {
    fontSize   : theme.typography.pxToRem(15),
    fontWeight : theme.typography.fontWeightRegular,
    fontFamily : `'Bowlby One SC', cursive`,
    color      : THEME.text,
  },
  accordion : {
    width        : '100%',
    marginTop    : 10,
    marginBottom : 10,
  },
}));

export default useStyles;
