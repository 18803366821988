import styled from 'styled-components';
import THEME from 'constants/theme';

const StyledInput = styled.label`
  position: relative;
  margin: auto;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  & .label {
    position: absolute;
    top: 20px;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    pointer-events: none;
  }
  & .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: -1;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  & input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
    color: #000;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }
  & input:hover {
    background: rgba(0, 0, 0, 0.04);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
  }
  & input:not(:placeholder-shown) + .label {
    color: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(0, -12px, 0) scale(0.75);
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  & input:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
    box-shadow: inset 0 -2px 0 ${THEME.accent};
  }
  & input:focus + .label {
    color: ${THEME.accent};
    -webkit-transform: translate3d(0, -12px, 0) scale(0.75);
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  & input:focus + .label + .focus-bg {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
  }
`;

export default StyledInput;
