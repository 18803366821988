import React from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';
import THEME from 'constants/theme';

export default function TopItem({ name, description, openGraphImageUrl, stargazers, url }) {
  const classes = useStyles();
  return (
    <a href={url} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItem alignItems='flex-start'>
        <ListItemAvatar>
          <Avatar alt='Remy Sharp' src={openGraphImageUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={description}
          secondaryTypographyProps={{ className: classes.secondaryText }}
        />
        <ListItemSecondaryAction>
          <div
            style={{
              display        : 'flex',
              justifyContent : 'center',
              alignItems     : 'center',
              flexDirection  : 'column',
            }}>
            <StarIcon style={{ color: THEME.accent }} />
            <Typography>{stargazers.totalCount}</Typography>
          </div>
        </ListItemSecondaryAction>
      </ListItem>
    </a>
  );
}

const useStyles = makeStyles((theme) => ({
  inline        : {
    display : 'inline',
  },
  secondaryText : {
    width : '80%',
  },
}));
