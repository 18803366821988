import React from 'react';
import StyledInput from './styles';

export default function TextInput({ text, onChange }) {
  return (
    <StyledInput htmlFor='inp' className='inp'>
      <input
        type='text'
        id='inp'
        placeholder='&nbsp;'
        value={text}
        onChange={(event) => onChange(event.target.value)}
      />
      <span className='label'>Search</span>
      <span className='focus-bg' />
    </StyledInput>
  );
}
