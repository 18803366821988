import React from 'react';
import styled from 'styled-components';
import THEME from 'constants/theme';

export default function Checkbox({ title = 'Title', checked = false, onChange }) {
  return (
    <StyledCheckbox htmlFor='popular_repos'>
      <input
        type='checkbox'
        id=' popular_repos'
        name='c-group'
        value={checked}
        onChange={(event) => onChange({ popular: event.target.checked })}
      />
      <span className='label-text'>{title}</span>
    </StyledCheckbox>
  );
}

const StyledCheckbox = styled.label`
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  flex: 1;
  & input {
    position: relative !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 8px;
    box-sizing: content-box;
    overflow: hidden;
  }
  & input:before {
    content: '';
    display: block;
    box-sizing: content-box;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    -webkit-transition: 0.2s border-color ease;
    transition: 0.2s border-color ease;
  }
  & input:checked:before {
    border-color: ${THEME.accent};
    -webkit-transition: 0.5s border-color ease;
    transition: 0.5s border-color ease;
  }
  & input:disabled:before {
    border-color: #ccc;
    background-color: #ccc;
  }
  & input:after {
    content: '';
    display: block;
    position: absolute;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background-color: ${THEME.accent};
    width: 16px;
    height: 16px;
    border-radius: 100vh;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
  & input[type="checkbox"]:before {
    border-radius: 4px;
  }
  & input[type="checkbox"]:after {
    width: 9.6px;
    height: 16px;
    border-radius: 0;
    -webkit-transform: translate(-50%, -85%) scale(0) rotate(45deg);
    transform: translate(-50%, -85%) scale(0) rotate(45deg);
    background-color: transparent;
    box-shadow: 4px 4px 0px 0px ${THEME.accent};
  }
  & input[type="checkbox"]:checked:after {
    -webkit-animation: toggleOnCheckbox 0.2s ease forwards;
    animation: toggleOnCheckbox 0.2s ease forwards;
  }
  & input[type="checkbox"].filled:before {
    border-radius: 4px;
    -webkit-transition: 0.2s border-color ease, 0.2s background-color ease;
    transition: 0.2s border-color ease, 0.2s background-color ease;
  }
  & input[type="checkbox"].filled:checked:not(:disabled):before {
    background-color: ${THEME.accent};
  }
  & input[type="checkbox"].filled:not(:disabled):after {
    box-shadow: 4px 4px 0px 0px white;
  }
  @-webkit-keyframes toggleOnCheckbox {
    0% {
      opacity: 0;
      -webkit-transform: translate(-50%, -85%) scale(0) rotate(45deg);
      transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }
    70% {
      opacity: 1;
      -webkit-transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
      transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }
    100% {
      -webkit-transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
      transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
  }
  @keyframes toggleOnCheckbox {
    0% {
      opacity: 0;
      -webkit-transform: translate(-50%, -85%) scale(0) rotate(45deg);
      transform: translate(-50%, -85%) scale(0) rotate(45deg);
    }
    70% {
      opacity: 1;
      -webkit-transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
      transform: translate(-50%, -85%) scale(0.9) rotate(45deg);
    }
    100% {
      -webkit-transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
      transform: translate(-50%, -85%) scale(0.8) rotate(45deg);
    }
  }
`;
