import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import Paper from '../paper';
import QUERY from './query';
import TopItem from './top_item';
import Loading from '../Loading';
import Title from '../title';

const useStyles = makeStyles((theme) => ({
  root : {
    width           : '100%',
    // maxWidth        : 360,
    backgroundColor : theme.palette.background.paper,
    top             : 0,
    marginTop       : 30,
  },
}));

export default function TopLibraries() {
  const { data, error, loading } = useQuery(QUERY);
  const classes = useStyles();
  return (
    <Paper style={{ marginTop: -25 }}>
      <Title>Learn something new!</Title>
      <Title>
        Top 15 Repositories<br />on Github
      </Title>
      <List className={classes.root}>
        {loading && !error ? (
          <Loading />
        ) : (
          data.search.edges.map((item, index) => <TopItem key={`LTI${index}`} {...item.node} />)
        )}
      </List>
    </Paper>
  );
}
