import { gql } from '@apollo/client';

const SEARCH_QUERY = ({ query, stars = '300', date = null }) => gql`
  query {
    search(query: "${query} language:JavaScript stars:>${stars} ${date
  ? 'created:>' + date
  : ''}", type: REPOSITORY, first: 25) {
      repositoryCount
      edges {
        node {
          ... on Repository {
            name
            description
            homepageUrl
            url
            openGraphImageUrl
            stargazers {
              totalCount
            }
            forks {
              totalCount
            }
            updatedAt
          }
        }
      }
    }
  }
`;

export default SEARCH_QUERY;
