import React from 'react';
import styled from 'styled-components';
import THEME from 'constants/theme';

export default function Accordion({ children }) {
  return (
    <StyledAccordion>
      <div className='tab'>
        <input type='checkbox' className='accordion-checkbox' id='advance-search-accordion' />
        <label className='tab-label' htmlFor='advance-search-accordion'>
          Advance Search
        </label>
        <div className='tab-content'>{children}</div>
      </div>
    </StyledAccordion>
  );
}

const StyledAccordion = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  input:checked + .tab-label {
    background: ${THEME.text};
  }
  input:checked.accordion-checkbox + .tab-label::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  input:checked.accordion-checkbox ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }

  & .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    & input.accordion-checkbox {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
    & .tab-label {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: 1em;
      background: ${THEME.text};
      color: ${THEME.backgroundColor};
      font-weight: bold;
      cursor: pointer;
      &:hover {
        background: ${THEME.primary};
      }
      &::after {
        width: 1em;
        height: 1em;
        text-align: center;
        -webkit-transition: all .35s;
        transition: all .35s;
      }
    }
    & .tab-content {
      max-height: 0;
      padding: 0 1em;
      color: #2c3e50;
      background: white;
      -webkit-transition: all .35s;
      transition: all .35s;
    }
  }
`;
