import React from 'react';
import styled from 'styled-components';
import THEME from 'constants/theme';

export default function radio_group({ onChange }) {
  function _handleChange(event) {
    onChange({ [event.target.name]: event.target.value });
  }
  return (
    <StyledRadioGroup>
      <span>By Date</span>
      <fieldset id='r-group' onChange={_handleChange}>
        <label htmlFor='year_label'>
          <input type='radio' id='year_label' name='date' value='year' />
          <span className='label-text'>Last Year</span>
        </label>
        <label htmlFor='month_label'>
          <input type='radio' id='month_label' name='date' value='moth' />
          <span className='label-text'>Last Month</span>
        </label>
        <label htmlFor='all_time_label'>
          <input type='radio' id='all_time_label' name='date' value='none' />
          <span className='label-text'>All Time</span>
        </label>
      </fieldset>
    </StyledRadioGroup>
  );
}

const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  & fieldset {
    border: none;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 20px;
    -webkit-box-align: start;
    align-items: flex-start;
    min-width: 400px;
    & label {
      & input {
        position: relative !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-sizing: content-box;
        overflow: hidden;
        &:before {
          content: '';
          display: block;
          box-sizing: content-box;
          width: 16px;
          height: 16px;
          border: 2px solid #ccc;
          -webkit-transition: 0.2s border-color ease;
          transition: 0.2s border-color ease;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          box-sizing: content-box;
          top: 50%;
          left: 50%;
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          background-color: ${THEME.accent};
          width: 16px;
          height: 16px;
          border-radius: 100vh;
          -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
        }
      }
      & input[type="radio"]:before {
        border-radius: 100vh;
      }
      & input[type="radio"]:after {
        width: 16px;
        height: 16px;
        border-radius: 100vh;
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
      }
      & input[type="radio"]:checked:after {
        -webkit-animation: toggleOnRadio 0.2s ease forwards;
        animation: toggleOnRadio 0.2s ease forwards;
      }
    }
  }
  @-webkit-keyframes toggleOnRadio {
    0% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
    }
    70% {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(0.9);
      transform: translate(-50%, -50%) scale(0.9);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) scale(0.8);
      transform: translate(-50%, -50%) scale(0.8);
    }
  }
  @keyframes toggleOnRadio {
    0% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
    }
    70% {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(0.9);
      transform: translate(-50%, -50%) scale(0.9);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) scale(0.8);
      transform: translate(-50%, -50%) scale(0.8);
    }
  }
`;
