import React from 'react';
import firebase from 'gatsby-plugin-firebase';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Logo from 'components/logo';
import TopLibraries from 'components/top_libraries';
import SearchSection from 'components/search_section';

const IndexPage = (props) => {
  React.useEffect(() => {
    firebase.analytics().logEvent('visited_home');
  }, []);
  return (
    <Layout>
      <SEO title='Home' />
      <div
        style={{
          display        : 'flex',
          justifyContent : 'center',
          alignItems     : 'center',
          flexDirection  : 'column',
          paddingTop     : 20,
        }}>
        <Logo />
        <TopLibraries />
        <SearchSection />
      </div>
    </Layout>
  );
};

export default IndexPage;
