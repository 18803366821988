import React from 'react';
import styled from 'styled-components';
import THEME from 'constants/theme';

export default function PrimaryButton({ onClick, children, styles = {}, ...rest }) {
  return (
    <StyledPrimaryButton onClick={onClick} styles={styles} {...rest}>
      {children}
    </StyledPrimaryButton>
  );
}

const StyledPrimaryButton = styled.button`
  height: 50px;
  background-color: ${THEME.inactiveText};
  color: ${THEME.backgroundColor};
  font-size: 1.3rem;
  font-family: 'Bowlby One SC', cursive;
  ${(props) => (props.fullWidth ? 'width: 100%;' : null)} border: none;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 0.3rem;
  border-radius: 8px;
  &:active {
    background-color: ${THEME.primary};
    box-shadow: 0 5px #666;
    transform: translateY(4px);
`;
